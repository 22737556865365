"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dumpSqlCondition = void 0;
const dumpSqlExpression_1 = require("./dumpSqlExpression");
const dumpSqlCommand_1 = require("./dumpSqlCommand");
function dumpSqlCondition(dmp, condition) {
    switch (condition.conditionType) {
        case 'binary':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.left);
            dmp.put(' %s ', condition.operator);
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.right);
            break;
        case 'isNull':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
            dmp.put(' ^is ^null');
            break;
        case 'isNotNull':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
            dmp.put(' ^is ^not ^null');
            break;
        case 'isEmpty':
            dmp.put('^trim(');
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
            dmp.put(") = ''");
            break;
        case 'isNotEmpty':
            dmp.put('^trim(');
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
            dmp.put(") <> ''");
            break;
        case 'and':
        case 'or':
            dmp.putCollection(` ^${condition.conditionType} `, condition.conditions, cond => {
                dmp.putRaw('(');
                dumpSqlCondition(dmp, cond);
                dmp.putRaw(')');
            });
            break;
        case 'like':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.left);
            dmp.put(dmp.dialect.ilike ? ' ^ilike ' : ' ^like ');
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.right);
            break;
        case 'notLike':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.left);
            dmp.put(' ^not ^like ');
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.right);
            break;
        case 'not':
            dmp.put('^not (');
            dumpSqlCondition(dmp, condition.condition);
            dmp.put(')');
            break;
        case 'exists':
            dmp.put('^exists (');
            (0, dumpSqlCommand_1.dumpSqlSelect)(dmp, condition.subQuery);
            dmp.put(')');
            break;
        case 'notExists':
            dmp.put('^not ^exists (');
            (0, dumpSqlCommand_1.dumpSqlSelect)(dmp, condition.subQuery);
            dmp.put(')');
            break;
        case 'between':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
            dmp.put(' ^between ');
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.left);
            dmp.put(' ^and ');
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.right);
            break;
        case 'expression':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
            break;
        case 'in':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
            dmp.put(' ^in (%,v)', condition.values);
            break;
        case 'notIn':
            (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
            dmp.put(' ^not ^in (%,v)', condition.values);
            break;
        case 'rawTemplate':
            let was = false;
            for (const item of condition.templateSql.split('$$')) {
                if (was) {
                    (0, dumpSqlExpression_1.dumpSqlExpression)(dmp, condition.expr);
                }
                dmp.putRaw(item);
                was = true;
            }
            break;
    }
}
exports.dumpSqlCondition = dumpSqlCondition;
