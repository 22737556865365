"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomGridDisplay = void 0;
const GridDisplay_1 = require("./GridDisplay");
class CustomGridDisplay extends GridDisplay_1.GridDisplay {
    constructor(tableName, columns, driver, config, setConfig, cache, setCache, dbinfo, serverVersion, isReadOnly = false, additionalcondition = null) {
        super(config, setConfig, cache, setCache, driver, dbinfo, serverVersion);
        this.tableName = tableName;
        this.additionalcondition = additionalcondition;
        this.customColumns = columns;
        this.columns = columns.map(col => ({
            columnName: col.columnName,
            headerText: col.columnLabel,
            uniqueName: col.columnName,
            uniquePath: [col.columnName],
            isPrimaryKey: col.isPrimaryKey,
            isForeignKeyUnique: false,
            schemaName: tableName.schemaName,
            pureName: tableName.pureName,
        }));
        this.changeSetKeyFields = columns.filter(x => x.isPrimaryKey).map(x => x.columnName);
        this.baseTable = Object.assign(Object.assign({}, tableName), { columns: this.columns.map(x => (Object.assign(Object.assign({}, tableName), { columnName: x.columnName, dataType: 'string' }))), foreignKeys: [] });
        this.filterable = true;
        this.sortable = true;
        this.groupable = false;
        this.editable = !isReadOnly;
        this.supportsReload = true;
    }
    createSelect(options = {}) {
        var _a;
        const select = this.createSelectBase(this.tableName, [], 
        // @ts-ignore
        // this.columns.map(col => ({
        //   columnName: col.columnName,
        // })),
        options, (_a = this.customColumns.find(x => x.isPrimaryKey)) === null || _a === void 0 ? void 0 : _a.columnName);
        select.selectAll = true;
        if (this.additionalcondition) {
            if (select.where) {
                select.where = {
                    conditionType: 'and',
                    conditions: [select.where, this.additionalcondition],
                };
            }
            else {
                select.where = this.additionalcondition;
            }
        }
        return select;
    }
}
exports.CustomGridDisplay = CustomGridDisplay;
