import { __awaiter } from "tslib";
import _ from 'lodash';
import { currentDatabase, getCurrentDatabase, getLockedDatabaseMode, openedTabs } from '../stores';
import { shouldShowTab } from '../tabpanel/TabsPanel.svelte';
import { callWhenAppLoaded, getAppLoaded } from './appLoadManager';
import { getConnectionInfo } from './metadataLoaders';
import { switchCurrentDatabase } from './common';
let lastCurrentTab = null;
openedTabs.subscribe(value => {
    var _a, _b;
    const newCurrentTab = (value || []).find(x => x.selected);
    if (newCurrentTab == lastCurrentTab)
        return;
    if (getLockedDatabaseMode() && getCurrentDatabase())
        return;
    const lastTab = lastCurrentTab;
    lastCurrentTab = newCurrentTab;
    // if (lastTab?.tabComponent == 'ConnectionTab') return;
    if (newCurrentTab) {
        const { conid, database } = newCurrentTab.props || {};
        if (conid && database && (conid != ((_a = lastTab === null || lastTab === void 0 ? void 0 : lastTab.props) === null || _a === void 0 ? void 0 : _a.conid) || database != ((_b = lastTab === null || lastTab === void 0 ? void 0 : lastTab.props) === null || _b === void 0 ? void 0 : _b.database))) {
            const doWork = () => __awaiter(void 0, void 0, void 0, function* () {
                const connection = yield getConnectionInfo({ conid });
                switchCurrentDatabase({
                    connection,
                    name: database,
                });
            });
            callWhenAppLoaded(doWork);
        }
    }
});
currentDatabase.subscribe(currentDb => {
    if (!getLockedDatabaseMode())
        return;
    if (!currentDb && !getAppLoaded())
        return;
    openedTabs.update(tabs => {
        const newTabs = tabs.map(tab => (Object.assign(Object.assign({}, tab), { selected: tab.selected && shouldShowTab(tab, true, currentDb) })));
        if (newTabs.find(x => x.selected))
            return newTabs;
        const selectedIndex = _.findLastIndex(newTabs, x => shouldShowTab(x));
        return newTabs.map((x, index) => (Object.assign(Object.assign({}, x), { selected: index == selectedIndex })));
    });
});
