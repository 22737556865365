"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detectSqlFilterBehaviour = void 0;
const filterBehaviours_1 = require("./filterBehaviours");
const commonTypeParser_1 = require("./commonTypeParser");
function detectSqlFilterBehaviour(dataType) {
    if (!dataType)
        return filterBehaviours_1.stringFilterBehaviour;
    if ((0, commonTypeParser_1.isTypeNumber)(dataType))
        return filterBehaviours_1.numberFilterBehaviour;
    if ((0, commonTypeParser_1.isTypeString)(dataType))
        return filterBehaviours_1.stringFilterBehaviour;
    if ((0, commonTypeParser_1.isTypeLogical)(dataType))
        return filterBehaviours_1.logicalFilterBehaviour;
    if ((0, commonTypeParser_1.isTypeDateTime)(dataType))
        return filterBehaviours_1.datetimeFilterBehaviour;
    return filterBehaviours_1.stringFilterBehaviour;
}
exports.detectSqlFilterBehaviour = detectSqlFilterBehaviour;
