"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewGridDisplay = void 0;
const GridDisplay_1 = require("./GridDisplay");
class ViewGridDisplay extends GridDisplay_1.GridDisplay {
    constructor(view, driver, config, setConfig, cache, setCache, dbinfo, serverVersion) {
        super(config, setConfig, cache, setCache, driver, dbinfo, serverVersion);
        this.view = view;
        this.columns = this.getDisplayColumns(view);
        this.formColumns = this.columns;
        this.filterable = true;
        this.sortable = true;
        this.groupable = false;
        this.editable = false;
        this.supportsReload = true;
        this.baseView = view;
    }
    getDisplayColumns(view) {
        var _a, _b;
        return (((_b = (_a = view === null || view === void 0 ? void 0 : view.columns) === null || _a === void 0 ? void 0 : _a.map(col => this.getDisplayColumn(view, col))) === null || _b === void 0 ? void 0 : _b.map(col => (Object.assign(Object.assign({}, col), { isChecked: this.isColumnChecked(col) })))) || []);
    }
    getDisplayColumn(view, col) {
        const uniquePath = [col.columnName];
        const uniqueName = uniquePath.join('.');
        return Object.assign(Object.assign({}, col), { pureName: view.pureName, schemaName: view.schemaName, headerText: col.columnName, uniqueName,
            uniquePath });
    }
    createSelect(options = {}) {
        const select = this.createSelectBase(this.view, this.view.columns, options);
        return select;
    }
}
exports.ViewGridDisplay = ViewGridDisplay;
