import { __rest } from "tslib";
import { getCurrentDatabase } from '../stores';
import { getConnectionLabel } from 'dbgate-tools';
import openNewTab from '../utility/openNewTab';
export default function newQuery(_a = {}) {
    var { tabComponent = 'QueryTab', icon = 'img sql-file', title = undefined, initialData = undefined, multiTabIndex = undefined } = _a, props = __rest(_a, ["tabComponent", "icon", "title", "initialData", "multiTabIndex"]);
    const currentDb = getCurrentDatabase();
    const connection = (currentDb === null || currentDb === void 0 ? void 0 : currentDb.connection) || {};
    const database = currentDb === null || currentDb === void 0 ? void 0 : currentDb.name;
    const tooltip = `${getConnectionLabel(connection)}\n${database}`;
    openNewTab({
        title: title || 'Query #',
        icon,
        tooltip,
        tabComponent,
        multiTabIndex,
        props: Object.assign(Object.assign({}, props), { conid: connection._id, database }),
    }, { editor: initialData });
}
export function newQueryDesign() {
    return newQuery({ tabComponent: 'QueryDesignTab', icon: 'img query-design' });
}
export function newDiagram() {
    return newQuery({ tabComponent: 'DiagramTab', icon: 'img diagram', title: 'Diagram #' });
}
export function newPerspective() {
    return newQuery({ tabComponent: 'PerspectiveTab', icon: 'img perspective', title: 'Perspective #' });
}
