"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.standardFilterBehaviours = exports.evalFilterBehaviour = exports.mongoFilterBehaviour = exports.datetimeFilterBehaviour = exports.logicalFilterBehaviour = exports.stringFilterBehaviour = exports.numberFilterBehaviour = void 0;
exports.numberFilterBehaviour = {
    supportEquals: true,
    supportNumberLikeComparison: true,
    supportNullTesting: true,
    supportSqlCondition: true,
    allowNumberToken: true,
};
exports.stringFilterBehaviour = {
    supportEquals: true,
    supportStringInclusion: true,
    supportEmpty: true,
    supportNumberLikeComparison: true,
    supportNullTesting: true,
    supportSqlCondition: true,
    allowStringToken: true,
    allowHexString: true,
};
exports.logicalFilterBehaviour = {
    supportBooleanValues: true,
    supportNullTesting: true,
    supportSqlCondition: true,
};
exports.datetimeFilterBehaviour = {
    supportNullTesting: true,
    supportSqlCondition: true,
    supportDatetimeSymbols: true,
    supportDatetimeComparison: true,
};
exports.mongoFilterBehaviour = {
    supportEquals: true,
    supportArrayTesting: true,
    supportNumberLikeComparison: true,
    supportStringInclusion: true,
    supportBooleanValues: true,
    supportExistsTesting: true,
    allowStringToken: true,
    allowNumberDualTesting: true,
    allowObjectIdTesting: true,
};
exports.evalFilterBehaviour = {
    supportEquals: true,
    supportStringInclusion: true,
    supportEmpty: true,
    supportNumberLikeComparison: true,
    supportNullTesting: true,
    allowStringToken: true,
};
exports.standardFilterBehaviours = {
    numberFilterBehaviour: exports.numberFilterBehaviour,
    stringFilterBehaviour: exports.stringFilterBehaviour,
    logicalFilterBehaviour: exports.logicalFilterBehaviour,
    datetimeFilterBehaviour: exports.datetimeFilterBehaviour,
    mongoFilterBehaviour: exports.mongoFilterBehaviour,
    evalFilterBehaviour: exports.evalFilterBehaviour,
};
